<template>
  <div
    ref="autocomplete"
    v-click-outside="clickOutside"
    :class="['mz-autocomplete', config.layout === 'reverse' ? 'reverse' : '']"
    @keydown="keyHandler"
  >
    <div ref="arrow" class="mz-arrow-wrapper">
      <div v-if="autocomplete && isLoaded" class="mz-arrow"></div>
    </div>
    <div v-if="autocomplete && isLoaded" class="mz-autocomplete-container">
      <div class="mz-body">
        <div class="mz-left">
          <template v-for="(section, index) in config.sideAreaSections">
            <b
              v-if="
                section.visible === true &&
                  section.modal === 'modal-popular' &&
                  suggestResult &&
                  suggestResult._ &&
                  suggestResult._.items &&
                  suggestResult._.items.length > 0
              "
              :key="`label-${index}`"
            >
              <template v-if="query() !== ''">
                {{ section.config.label }}
              </template>
              <template v-if="query() === '' && config.popularOnFocus">
                {{ section.config.labelPopular }}
              </template>
            </b>
            <div
              v-if="
                section.visible === true &&
                  section.modal === 'modal-popular' &&
                  suggestResult &&
                  suggestResult._ &&
                  suggestResult._.items &&
                  suggestResult._.items.length > 0
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div
                v-for="item in suggestResult._.items"
                :key="item.text"
                tabindex="0"
                :class="{
                  'mz-suggest-item': true,
                  'mz-active': currentSuggestion === item.text
                }"
                @click.prevent="clickSearch(item.text)"
                @focus="setCurrentSuggestion(item.text, true)"
                @mouseenter.prevent="setCurrentSuggestion(item.text)"
              >
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="
                section.visible === true &&
                  section.modal === 'modal-side-custom'
              "
              :key="index"
              class="mz-suggest-list"
              v-html="section.config.html"
            />
            <div
              v-if="
                section.visible === true && section.modal === 'modal-facets'
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div v-for="(group, idx) in section.config.facets" :key="idx">
                <b
                  v-if="
                    suggestResult &&
                      suggestResult[group.facet.name] &&
                      suggestResult[group.facet.name].items
                  "
                >
                  {{ group.label }}
                </b>
                <div
                  v-if="
                    suggestResult &&
                      suggestResult[group.facet.name] &&
                      suggestResult[group.facet.name].items &&
                      suggestResult[group.facet.name].items.length > 0
                  "
                  :key="index"
                  class="mz-suggest-list"
                >
                  <div
                    v-for="item in suggestResult[group.facet.name].items"
                    :key="item.original"
                    tabindex="0"
                    class="mz-suggest-item"
                    @click.prevent="clickFacet(group.facet, item.original)"
                  >
                    {{ item.original | value }}
                  </div>
                </div>
              </div>
            </div>
            <b
              v-if="
                section.visible === true &&
                  section.modal === 'modal-recent' &&
                  suggestRecent &&
                  suggestRecent.length > 0
              "
              :key="`recent-${index}`"
            >
              {{ section.config.label }}
            </b>
            <div
              v-if="
                section.visible === true &&
                  section.modal === 'modal-recent' &&
                  suggestRecent &&
                  suggestRecent.length > 0
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div
                v-for="item in suggestRecent"
                :key="item.text"
                tabindex="0"
                :class="{
                  'mz-suggest-item': true
                }"
                @click.prevent="clickRecent(item.text)"
              >
                {{ item.text }}
                <a
                  class="mz-suggest-delete"
                  href="#"
                  @click.stop.prevent="deleteSearch(item.text)"
                >
                  del
                </a>
              </div>
            </div>
          </template>
        </div>
        <div class="mz-right">
          <template v-for="(section, index) in config.mainAreaSections">
            <template
              v-if="
                section.visible === true && section.modal === 'modal-top-items'
              "
            >
              <a
                v-if="toggleView"
                :key="`list-${index}`"
                href="#"
                :class="{
                  'mz-btn': true,
                  'mz-btn-sm': true,
                  'mz-active': view === 'list'
                }"
                @click.prevent="changeView('list')"
              >
                <fa :icon="faBars" fw />
              </a>
              <a
                v-if="toggleView"
                :key="`grid-${index}`"
                href="#"
                style="margin-right:5px;"
                :class="{
                  'mz-btn': true,
                  'mz-btn-sm': true,
                  'mz-active': view === 'grid'
                }"
                @click.prevent="changeView('grid')"
              >
                <fa :icon="faThLarge" fw />
              </a>
              <b v-if="suggestItems.total" :key="`text-${index}`">
                <template v-if="query() !== ''">
                  {{ section.config.label }}
                  <kbd>
                    {{ currentSuggestion || complete || query() || 'empty' }}
                  </kbd>
                </template>
                <template v-if="query() === '' && config.popularOnFocus">
                  {{ section.config.labelPopular }}
                </template>
              </b>
              <span
                v-if="suggestItems.total"
                :key="`summary-${index}`"
                class="mz-summary-count mz-hidden-xs"
                style="float:right"
              >
                {{
                  $t('SummaryCount', {
                    total: suggestItems.total,
                    took: suggestItems.took
                  })
                }}
              </span>
              <div v-if="isListView" :key="index" class="mz-row">
                <div class="mz-autocomplete-list">
                  <template v-for="(hit, idx) of suggestItems.items">
                    <slot
                      name="list-item"
                      :hit="hit"
                      :index="idx"
                      :primary-key="primaryKey"
                      :config="config"
                      :is-hidden="isHidden"
                      :on-item-click="onItemClick"
                    ></slot>
                  </template>
                </div>
              </div>
              <div v-if="isGridView" :key="index" class="mz-row">
                <div class="mz-autocomplete-grid">
                  <template v-for="(hit, idx) of suggestItems.items">
                    <slot
                      name="grid-item"
                      :hit="hit"
                      :index="idx"
                      :primary-key="primaryKey"
                      :config="config"
                      :is-hidden="isHidden"
                      :on-item-click="onItemClick"
                    ></slot>
                  </template>
                </div>
              </div>
            </template>
            <div
              v-if="
                section.visible === true &&
                  section.modal === 'modal-main-custom'
              "
              :key="index"
              class="mz-row"
              v-html="section.config.html"
            />
          </template>
        </div>
      </div>
      <div class="mz-footer"></div>
    </div>
  </div>
</template>

<script>
/* global mz */
import { debounce, indexOf, isEmpty } from 'lodash'
import { mapState, mapGetters } from 'vuex'
import Fa from 'vue-fa'
import { faSearch, faBars, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { createPopper } from '@popperjs/core'
import { getKeyFromComplex, getValueFromComplex } from '@/helpers'
import Component from './../mixins/Component.js'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'BaseSearchBox',
  components: {
    Fa
  },
  directives: {
    ClickOutside
  },
  mixins: [Component],
  props: {
    input: {
      type: HTMLInputElement,
      required: true
    },
    searchUrl: {
      type: String,
      required: false,
      default: undefined
    },
    queryParam: {
      type: String,
      required: false,
      default: 'q'
    },
    placement: {
      type: String,
      required: false,
      default: undefined
    },
    delay: {
      type: Number,
      required: false,
      default: 0
    },
    queryOnHover: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      popper: null,
      internalView: false,
      autocomplete: false,
      faSearch,
      faBars,
      faThLarge
    }
  },
  computed: {
    ...mapGetters('history', ['recentSearches']),
    ...mapState('search', [
      'suggestHidden',
      'hits',
      'primaryKey',
      'pagination',
      'pageSize',
      'keyword',
      'queryId',
      'complete',
      'suggestResult',
      'suggestItems',
      'currentSuggestion',
      'fallbackMode',
      'config',
      'translation'
    ]),
    resultsCount() {
      const topItems = this.config.mainAreaSections.find(
        x => x.modal === 'modal-top-items'
      )
      if (!topItems || !topItems.config) {
        return 10
      }
      return topItems.config.count || 10
    },
    suggestRecent() {
      if (!this.recentSearches) {
        return []
      }
      const count = this.config.recentCount || 5
      return this.recentSearches(count)
    },
    toggleView() {
      return this.config.listView && this.config.gridView
    },
    view: {
      get() {
        if (this.internalView) {
          return this.internalView
        }
        let view = false
        if (this.$cookies) {
          view = this.$cookies.get('autcomplete-view')
        }
        if (view === 'list' && !this.config.listView) {
          return 'grid'
        }
        if (view === 'grid' && !this.config.gridView) {
          return 'list'
        }
        if (!view) {
          view = this.config.listView ? 'list' : 'grid'
        }
        return view
      },
      set(val) {
        this.internalView = val
        if (this.$cookies)
          this.$cookies.set('autcomplete-view', val, { sameSite: 'strict' })
      }
    },
    isLoaded() {
      return this.suggestItems.items && this.suggestItems.items.length > 0
    },
    isGridView() {
      return this.view === 'grid' && this.isLoaded
    },
    isListView() {
      return this.view === 'list' && this.isLoaded
    },
    finalPlacement() {
      if (this.placement) {
        return this.placement
      }
      return this.config.placement || 'bottom-start'
    },
    fallbackPlacements() {
      if (this.finalPlacement === 'bottom-start') {
        return ['bottom', 'bottom-end']
      }
      if (this.finalPlacement === 'bottom-end') {
        return ['bottom', 'bottom-start']
      }
      if (this.finalPlacement === 'bottom') {
        return ['bottom-end', 'bottom-start']
      }
      return ['bottom']
    }
  },
  watch: {
    keyword: {
      handler() {
        this.$emit('change', this.keyword)
        if (this.query() === '') {
          this.$store.dispatch('search/setComplete', '', { root: true })
        }
      },
      immediate: true
    },
    autocomplete: {
      async handler(val, oldVal) {
        // do not render autocomplete in fallback mode
        if (this.fallbackMode) {
          return
        }
        if (val) {
          await this.showPopper()
        } else {
          this.hidePopper()
        }
      }
    }
  },
  beforeCreate() {
    const param = this.$options.propsData.queryParam
    if (param === undefined) {
      return
    }
    this.$store.dispatch('search/setQueryParam', param, { root: true })
  },
  beforeDestroy() {
    this.removePopper()
  },
  mounted() {
    const self = this

    this.popupItem = this.$el
    this.input.setAttribute('autocomplete', 'off')
    this.input.setAttribute('autocorrect', 'off')
    this.input.addEventListener('focus', self.onFocus)
    this.input.addEventListener('click', self.onFocus)
    this.input.addEventListener('keydown', self.keyDown)
    this.input.addEventListener('input', self.changeSuggest)
    this.input.addEventListener('keypress', event => {
      if (event.key === 'Enter') {
        self.doEnter()
        event.preventDefault()
      }
    })
    this.input.addEventListener('keuyup', event => {
      self.doSuggest()
      event.preventDefault()
    })
    this.$emit('change', this.keyword)
    this.setupPopper()
  },
  methods: {
    query() {
      return this.input.value
    },
    removePopper() {
      if (!this.popper) {
        return
      }
      this.popper.destroy()
    },
    setupPopper() {
      // do not setup when config is empty
      if (isEmpty(this.config)) {
        return
      }
      // remove if already created
      this.removePopper()

      const autocomplete = this.$refs.autocomplete
      const arrow = this.$refs.arrow
      const popperConfig = {
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrow,
              padding: 20
            }
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: this.fallbackPlacements,
              padding: 5
            }
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 5,
              escapeWithReference: true
            }
          },
          {
            name: 'hide',
            options: {}
          }
        ],
        placement: this.finalPlacement
      }
      this.popper = createPopper(this.input, autocomplete, popperConfig)
    },
    async showPopper() {
      this.input.setAttribute('data-show', '')
      this.$refs.autocomplete.setAttribute('data-show', '')
      this.$emit('autocomplete-open')
      const state = await this.popper.update()
    },
    clickOutside() {
      if (this.input.form && this.input.form.action.includes(this.searchUrl)) {
        if (this.input.form.contains(event.target)) return
      } else {
        if (event.target == this.input) return
      }
      this.autocomplete = false
    },
    hidePopper() {
      this.input.removeAttribute('data-show')
      this.$refs.autocomplete.removeAttribute('data-show')
      this.$emit('autocomplete-close')
    },
    isHidden(hit) {
      if (!this.suggestHidden || !hit.item || !hit.item[this.primaryKey]) {
        return false
      }
      return (
        indexOf(this.suggestHidden, hit.item[this.primaryKey].toString()) !== -1
      )
    },
    addSearch(payload) {
      this.$store.dispatch('history/addSearch', payload, { root: true })
    },
    deleteSearch(payload) {
      this.$store.dispatch('history/deleteSearch', payload, { root: true })
    },
    trackEvent(event) {
      const final = {
        ...event,
        context: {
          visitId: this.$cookies ? this.$cookies.get('mz_visitId') : '',
          visitorId: this.$cookies ? this.$cookies.get('mz_visitorId') : ''
        }
      }
      if (mz && mz.track) {
        mz.track('autocomplete', final)
      }
    },
    onItemClick(event) {
      const final = {
        ...event,
        keyword: this.query(),
        complete: this.complete,
        suggestion: this.currentSuggestion
      }
      this.trackEvent(final)
    },
    changeView(view) {
      this.view = view
      this.$emit('change-autocomplete-view')
    },
    keyHandler(event) {
      if (event.key === 'Escape') {
        this.autocomplete = false
        return
      }
      if (event.key === 'Enter') {
        this.$emit('change', this.currentSuggestion)
        this.$store.dispatch('search/setComplete', '', { root: true })
        this.doSearch(true)
        return
      }
      // arrow down
      if (
        (event.key === 'Tab' && !event.shiftKey) ||
        event.key === 'ArrowDown'
      ) {
        const nextElement = event.target.nextElementSibling
        if (nextElement) {
          nextElement.focus()
        }
        event.preventDefault()
      }

      // arrow up
      if (event.key === 'ArrowUp') {
        const previousElement = event.target.previousElementSibling
        if (previousElement) {
          previousElement.focus()
        }
        event.preventDefault()
      }
    },
    setCurrentSuggestion(sug, focus) {
      if (sug !== this.currentSuggestion) {
        this.$store.dispatch('search/setCurrentSuggestion', sug, { root: true })
        if (!this.queryOnHover && !focus) {
          return
        }
        this.getAutocomplete(
          this,
          this.currentSuggestion,
          true,
          this.resultsCount
        )
      }
    },
    onFocus() {
      const self = this
      if (this.delay > 0) {
        setTimeout(() => self.delayedOnFocus(), this.delay)
      } else {
        self.delayedOnFocus()
      }
    },
    delayedOnFocus() {
      if (this.query() !== '' || this.config.popularOnFocus) {
        this.autocomplete = true
        if (this.isLoaded) {
          return
        }
        this.runAutocomplete(this.query(), false, this.resultsCount)
      }
    },
    clearKeyword() {
      this.$emit('change', '')
      this.$store.dispatch('search/setComplete', '', { root: true })
      this.$store.dispatch('search/setCurrentSuggestion', '', { root: true })
      this.$store.dispatch('search/clearAutocomplete', '', { root: true })
      this.doSearch(false)
    },
    processClick(event, keyword) {
      this.trackEvent(event)
      this.addSearch(event)
      this.$store.dispatch('search/setComplete', '', { root: true })
      this.$store.dispatch('search/setCurrentSuggestion', '', { root: true })
      this.$emit('change', keyword)
      this.doSearch(true)
    },
    clickRecent(keyword) {
      // clear autocomplete to run autocomplete query on focus
      this.$store.dispatch('search/clearAutocomplete', '', { root: true })

      // track suggestion click event
      const event = {
        type: 'recent',
        keyword: '',
        complete: '',
        suggestion: keyword
      }
      this.processClick(event, keyword)
    },
    clickSearch(keyword) {
      // track suggestion click event
      const event = {
        type: 'suggestion',
        keyword: this.query(),
        complete: '',
        suggestion: keyword
      }
      this.processClick(event, keyword)
    },
    clickFacet(facet, original) {
      this.trackEvent({
        type: 'facet',
        keyword: this.query(),
        complete: this.complete,
        suggestion: this.currentSuggestion,
        name: facet.name,
        value: original
      })
      const key = getKeyFromComplex(original)
      const value = getValueFromComplex(original)

      if (this.searchUrl) {
        if (window.location.pathname !== this.searchUrl) {
          window.location = `${this.searchUrl}?mz.${facet.name}=${key}`
          return
        }
      }

      const last = {
        name: facet.name,
        label: facet.label,
        key: key,
        value: value,
        action: 'add'
      }
      this.$emit('change', '')
      this.$store.dispatch('search/setComplete', '', { root: true })
      this.$store.dispatch('search/setCurrentSuggestion', '', { root: true })
      this.$store.dispatch('search/clearAutocomplete', '', { root: true })
      this.$store.dispatch('search/clearAllSelections', {}, { root: true })
      this.$store.dispatch('search/updateSelections', last, { root: true })
      this.$store.dispatch('search/setPage', 1, { root: true })
      // close autocomplete
      this.autocomplete = false
      // run query
      if (this.$bus) this.$bus.emit('run-query')
    },
    doEnter() {
      // track complete event
      if (this.complete && this.complete !== this.query()) {
        const event = {
          type: 'complete',
          complete: this.complete,
          keyword: this.query(),
          suggestion: this.currentSuggestion
        }
        this.trackEvent(event)
        this.addSearch(event)
        this.doSearch(true)
        return
      }
      // track suggest event
      if (this.currentSuggestion && this.currentSuggestion !== this.query()) {
        const event = {
          type: 'suggestion',
          complete: this.complete,
          keyword: this.query(),
          suggestion: this.currentSuggestion
        }
        this.trackEvent(event)
        this.addSearch(event)
        this.doSearch(true)
        return
      }
      const event = {
        type: 'search',
        complete: this.complete,
        keyword: this.query(),
        suggestion: this.currentSuggestion
      }
      this.addSearch(event)
      this.doSearch(true)
    },
    doSearch(clearSelections) {
      this.autocomplete = false
      this.$emit(
        'change',
        this.complete || this.currentSuggestion || this.query()
      )
      if (this.searchUrl) {
        if (
          this.input.form &&
          this.input.form.action.includes(this.searchUrl)
        ) {
          this.input.form.submit()
          return
        }
        if (window.location.pathname !== this.searchUrl) {
          window.location = `${this.searchUrl}?${
            this.queryParam
          }=${this.query()}`
          return
        }
      }
      const last = {
        name: 'Keyword',
        label: this.$t('Keyword'),
        key: '_Keyword',
        value: this.query(),
        action: this.query() === '' ? 'remove-all' : 'replace'
      }
      if (clearSelections) {
        this.$store.dispatch('search/clearAllSelections', {}, { root: true })
      }
      this.$store.dispatch('search/updateSelections', last, { root: true })
      this.$store.dispatch('search/setKeyword', this.query(), { root: true })
      this.$store.dispatch('search/setPage', 1, { root: true })
      if (this.$bus) this.$bus.emit('run-query')
    },
    doSuggest(event) {
      if (event.key === 'Escape' || event.key === 'Enter') {
        this.autocomplete = false
      }
      if (event.key === 'Enter') {
        if (this.complete || this.currentSuggestion) {
          this.$emit('change', this.complete || this.currentSuggestion)
          this.$store.dispatch('search/setComplete', '', {
            root: true
          })
        }
      }
    },
    getAutocomplete: debounce((vm, keyword, skipSuggestions, resultsCount) => {
      vm.runAutocomplete(keyword, skipSuggestions, resultsCount)
    }, 250),
    runAutocomplete(keyword, skipSuggestions, resultsCount) {
      if (this.$bus) {
        this.$bus.emit(
          'run-autocomplete',
          keyword,
          skipSuggestions,
          resultsCount
        )
      }
    },
    changeSuggest() {
      this.$store.dispatch('search/setComplete', '', { root: true })
      this.$store.dispatch('search/setCurrentSuggestion', '', { root: true })
      this.autocomplete = true
      this.getAutocomplete(this, this.query(), false, this.resultsCount)
    },
    keyDown() {
      if (event.key === 'Escape') {
        this.autocomplete = false
        return
      }
      if (
        (event.key === 'Tab' && event.shiftKey === false) ||
        event.key === 'ArrowDown' ||
        event.key === 'ArrowUp'
      ) {
        let active = ''

        // find current suggestion and set focus
        active = document.querySelector('.mz-suggest-item.mz-active')
        // set focus on first suggestion
        active = active || document.querySelector('.mz-suggest-item')
        if (active) {
          active.focus()
          event.preventDefault()
        }
      }
    }
  }
}
</script>

<style>
.mz-autocomplete {
  position: fixed;
  z-index: 1501;
  width: 750px;
}

.mz-autocomplete .mz-autocomplete-container {
  display: block;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  padding: 5px;
  top: 18px;
  border-radius: 0.3em;
  line-height: 1.42857143;
  background-color: #fff;
}

.mz-autocomplete .mz-btn.mz-btn-sm {
  border-color: transparent;
  background-color: transparent;
  padding: 3px 6px;
}

.mz-autocomplete .mz-btn.mz-btn-sm.mz-active {
  color: #333;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.mz-autocomplete .mz-arrow-wrapper {
  z-index: 100;
}

.mz-autocomplete .mz-arrow {
  position: absolute;
  top: 11px;
  box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.25);
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  background-color: #fff;
}

.mz-autocomplete .mz-body {
  display: flex;
  width: 100%;
}

.mz-autocomplete.reverse .mz-body {
  flex-flow: row-reverse;
}

.mz-autocomplete .mz-left {
  width: 25%;
  min-width: 150px;
  display: inline-block;
  padding: 10px;
  background-color: #ecf0f5;
  position: relative;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  max-height: 500px;
  overflow-y: auto;
  z-index: 101;
}

.mz-autocomplete.reverse .mz-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  z-index: 101;
}

.mz-autocomplete .mz-right {
  display: inline-block;
  background-color: #fff;
  width: 75%;
  padding: 10px;
  overflow-y: auto;
  max-height: 500px;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  z-index: 101;
}

.mz-autocomplete.reverse .mz-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  z-index: 101;
}

.mz-autocomplete .mz-suggest-list {
  padding: 10px 0 10px 0;
}

.mz-autocomplete .mz-suggest-item {
  width: 100%;
  padding: 3px 3px 3px 10px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  text-transform: capitalize;
}

.mz-autocomplete .mz-suggest-item:hover,
.mz-autocomplete .mz-suggest-item:focus {
  background-color: #bbc4e4;
}

.mz-autocomplete .mz-suggest-item.mz-active {
  background-color: #bbc4e4;
}

.mz-autocomplete .mz-row {
  margin-top: 10px;
}

.mz-autocomplete-list {
  padding-top: 0;
  display: flex;
  flex-flow: column;
}

.mz-autocomplete .mz-autocomplete-list-item {
  padding: 0 4px;
  display: flex;
  flex-flow: row;
}

.mz-autocomplete .mz-autocomplete-list-item.disabled,
.mz-autocomplete .mz-autocomplete-grid-item.disabled {
  opacity: 0.3;
}

.mz-autocomplete .mz-autocomplete-list-item:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.mz-autocomplete .mz-list-item-column {
  padding: 5px;
}

.mz-autocomplete .mz-autocomplete-grid {
  padding-top: 0;
  display: flex;
  flex-flow: row wrap;
}

.mz-autocomplete .mz-autocomplete-grid-item:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.mz-autocomplete .mz-autocomplete-grid-item {
  padding: 5px 9px;
  max-width: 250px;
  flex-flow: column nowrap;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mz-autocomplete .mz-autocomplete-grid-item:hover {
  background-color: auto;
}

.mz-autocomplete .mz-right::-webkit-scrollbar,
.mz-autocomplete .mz-left::-webkit-scrollbar {
  width: 5px;
  position: fixed;
  right: 0;
}

.mz-autocomplete .mz-right::-webkit-scrollbar-thumb,
.mz-autocomplete .mz-left::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  transition: background-color 2s;
}

.mz-autocomplete .mz-right:hover::-webkit-scrollbar-thumb,
.mz-autocomplete .mz-left:hover::-webkit-scrollbar-thumb {
  background: #d9dbdc;
}

.mz-autocomplete .mz-right::-webkit-scrollbar-thumb:hover,
.mz-autocomplete .mz-left::-webkit-scrollbar-thumb:hover {
  background: #d9dbdc;
}

.mz-autocomplete .mz-right code,
.mz-autocomplete .mz-right kbd {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mz-autocomplete .mz-right kbd {
  padding: 2px 4px;
  font-size: 0.9em;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
}
.mz-autocomplete .mz-suggest-delete {
  float: right;
  font-size: 0.8em;
  padding-top: 0.2em;
  text-transform: none;
}

@media (max-width: 767px) {
  .mz-autocomplete .mz-autocomplete-container {
    width: calc(100vw - 10px);
  }

  .mz-autocomplete .mz-body {
    display: grid;
  }

  .mz-autocomplete .mz-left,
  .mz-autocomplete.reverse .mz-left {
    width: 100%;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .mz-autocomplete .mz-right,
  .mz-autocomplete.reverse .mz-right {
    max-height: 400px;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
  }
}
</style>
